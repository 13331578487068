import axios from "axios";
import { setLocalStorageItem } from "storage";
import { pollStorageForToken } from "./helpers";
import {
  ErrorsEnum,
  FetchRefreshTokenType,
  GetAccessTokenReturnType,
  GetRefreshTokenType,
} from "types";

let fetchingRefreshToken = false;

const fetchRefreshTokenFromApi = async (props: FetchRefreshTokenType) => {
  try {
    const {
      address,
      maiarIdApi,
      timeout,
      loginToken,
      signature,
      data = {},
    } = props;

    const { data: maiarIdAccessToken } = await axios.post(
      `${maiarIdApi}/login`,
      {
        signature,
        loginToken,
        address,
        data,
      },
      { timeout }
    );
    setLocalStorageItem({
      address,
      item: { maiarIdAccessToken },
      expires: maiarIdAccessToken.refreshTokenExpirationTime,
    });

    return maiarIdAccessToken;
  } catch (err) {
    fetchingRefreshToken = false;
    throw err;
  }
};

export async function getRefreshToken(
  props: GetRefreshTokenType
): Promise<GetAccessTokenReturnType> {
  const { address, loginToken, signature } = props;
  if (!loginToken || !signature) {
    throw ErrorsEnum.loginRequired;
  } else {
    if (fetchingRefreshToken) {
      return await pollStorageForToken(address);
    } else {
      fetchingRefreshToken = true;
      const response = await fetchRefreshTokenFromApi({
        ...props,
        loginToken,
      });
      fetchingRefreshToken = false;
      return response;
    }
  }
}
