import axios from "axios";

export interface InitProps {
  maiarIdApi: string;
  timeout?: number;
}
export const init = async ({
  maiarIdApi,
  timeout,
}: InitProps): Promise<string> => {
  return axios
    .post(`${maiarIdApi}/login/init`, { ...(timeout ? { timeout } : {}) })
    .then(({ data }) => {
      if (data) {
        return data.loginToken;
      }
    });
};
