export const isValid = (jwtToken = "") => {
  try {
    const [, encodedToken] = jwtToken.split(".");
    const token = Buffer.from(encodedToken, "base64").toString();
    const { exp } = JSON.parse(token);
    const currentUnixTimestamp = Math.floor(Date.now() / 1000);
    const oneMinTreshold = 60;
    //if the token has one minute until expiration, make it invalid to fetch a new token until the old one expires
    return currentUnixTimestamp + oneMinTreshold < exp;
  } catch {
    return false;
  }
};

export default isValid;
