import { getLocalStorageItem, LocalStorageKeyEnum } from "storage";
import { isValid } from "./isValid";
import { GetAccessTokenReturnType } from "types";

export async function pollStorageForToken(
  address: string
): Promise<GetAccessTokenReturnType> {
  return new Promise((resolve, reject) => {
    let retry = 0;
    const polling = setInterval(async () => {
      if (retry <= 10) {
        const newToken = await getLocalStorageItem({
          address,
          item: LocalStorageKeyEnum.maiarIdAccessToken,
        });
        if (isValid(newToken?.accessToken)) {
          clearInterval(polling);
          resolve(newToken);
        } else {
          retry = retry + 1;
        }
      } else {
        clearInterval(polling);
        reject("New token not found");
      }
    }, 250);
  });
}

export default pollStorageForToken;
