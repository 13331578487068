import axios from "axios";
import {
  getLocalStorageItem,
  LocalStorageKeyEnum,
  setLocalStorageItem,
} from "storage";

import {
  FetchAccessTokenType,
  GetAccessTokenReturnType,
  GetAccessTokenPropsType,
  TokenType,
  ErrorsEnum,
} from "types";
import { isValid, pollStorageForToken } from "./helpers";

let fetchingAccessToken = false;

async function fetchAccessTokenFromApi(props: FetchAccessTokenType) {
  const { address, maiarIdApi, timeout, token } = props;

  try {
    const { data } = await axios.post(
      `${maiarIdApi}/login/access-token-generate`,
      {
        refreshToken: token.refreshToken,
      },
      { ...(timeout ? { timeout } : {}) }
    );
    if (data) {
      const maiarIdAccessToken: GetAccessTokenReturnType = {
        accessToken: data.accessToken,
        accessTokenExpirationTime: data.expirationTime,
        refreshTokenExpirationTime: token.refreshTokenExpirationTime,
        refreshToken: token.refreshToken,
      };

      setLocalStorageItem({
        address,
        item: { maiarIdAccessToken },
        expires: Number(token.refreshTokenExpirationTime),
      });

      return maiarIdAccessToken;
    } else {
      console.error(ErrorsEnum.unableToGenerateAccessToken);
      return token;
    }
  } catch (err) {
    console.error(ErrorsEnum.unableToGetAccessToken, err);
    return token;
  }
}

async function resolveToken(props: FetchAccessTokenType) {
  const { address, token } = props;
  const valid = isValid(token.accessToken);
  if (token?.accessToken && valid) {
    return token;
  }
  if (fetchingAccessToken) {
    return await pollStorageForToken(address);
  } else {
    fetchingAccessToken = true;
    const response = await fetchAccessTokenFromApi(props);
    fetchingAccessToken = false;
    return response;
  }
}

export async function getAccessToken(
  props: GetAccessTokenPropsType
): Promise<GetAccessTokenReturnType | null> {
  const token: TokenType = await getLocalStorageItem({
    address: props.address,
    item: LocalStorageKeyEnum.maiarIdAccessToken,
  });
  if (!isValid(token?.refreshToken) || props.shouldInvalidateToken) {
    props?.onTokenExpired?.();
    return null;
  }
  return await resolveToken({ ...props, token });
}
