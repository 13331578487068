import * as local from "./local";

export enum LocalStorageKeyEnum {
  maiarIdAccessToken = "maiarIdAccessToken",
}

const localStorageKeyPrefix = "core";

export async function getLocalStorageItem({
  address,
  item,
}: {
  address?: string;
  item: LocalStorageKeyEnum;
}) {
  const key = [localStorageKeyPrefix, ...(address ? [address] : []), item];
  return await local.getItem(key.join("."));
}

export const setLocalStorageItem = ({
  address,
  item,
  expires,
}: {
  address: string;
  item: {
    [LocalStorageKeyEnum.maiarIdAccessToken]?: {
      accessToken: string;
      accessTokenExpirationTime: string;
      refreshToken: string;
    };
  };
  expires: number | false;
}) => {
  const [itemName] = Object.keys(item);

  local.setItem({
    key: `${localStorageKeyPrefix}.${address}.${itemName}`,
    data: item[itemName],
    expires,
  });
};

export const removeLocalStorageItem = async (item: LocalStorageKeyEnum) => {
  const localStorageEntries = await getStorageEntries(localStorage);
  localStorageEntries
    .filter(
      (x) => x.startsWith(`${localStorageKeyPrefix}.`) && x.endsWith(`.${item}`)
    )
    .forEach((x) => local.removeItem(x));
};

export const clearCoreLocalStorage = () => {
  Object.entries(localStorage)
    .map(([x]) => x)
    .filter((x) => x.startsWith(`${localStorageKeyPrefix}.`))
    .forEach((x) => local.removeItem(x));
};

async function getStorageEntries(storage: any) {
  let storageEntries: string[];
  if (typeof storage?.getAllKeys == "function") {
    storageEntries = await storage?.getAllKeys();
  } else {
    storageEntries = Object.entries(storage).map(([x]) => x);
  }
  return storageEntries;
}

export { local };
