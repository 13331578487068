import React from "react";

export interface AccessTokenManagerContextType {
  hasAccessToken: boolean;
  isInitialized: boolean;
}
export interface AccessTokenManagerContextProviderType {
  hasAccessToken: boolean;
  isInitialized: boolean;
  children: React.ReactNode;
}

export const AccessTokenManagerContext = React.createContext(
  {} as AccessTokenManagerContextType
);

export function AccessTokenManagerContextProvider({
  hasAccessToken,
  isInitialized,
  children,
}: AccessTokenManagerContextProviderType) {
  return (
    <AccessTokenManagerContext.Provider
      value={{ hasAccessToken, isInitialized }}
    >
      {children}
    </AccessTokenManagerContext.Provider>
  );
}
