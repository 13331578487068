import { setLocalStorageItem } from "storage";

const mandatoryKeys = [
  "accessToken",
  "accessTokenExpirationTime",
  "refreshToken",
  "refreshTokenExpirationTime",
  "address",
];

export function urlDirectLogin() {
  if (
    typeof window === "undefined" ||
    !window?.location?.pathname ||
    typeof document === "undefined"
  ) {
    return false;
  }
  let params: any = {};
  if (window?.location?.search) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams);
  }
  const allKeysArePresent = mandatoryKeys.every((key) => key in params);
  const { pathname } = window.location;

  if (!allKeysArePresent) {
    return false;
  }

  const {
    accessToken,
    accessTokenExpirationTime,
    refreshToken,
    refreshTokenExpirationTime,
    address,
    ...remainingParams
  } = params;
  const maiarIdAccessToken = {
    accessToken,
    accessTokenExpirationTime,
    refreshToken,
    refreshTokenExpirationTime,
  };
  setLocalStorageItem({
    address,
    item: { maiarIdAccessToken },
    expires: maiarIdAccessToken.refreshTokenExpirationTime,
  });

  const newUrlParams = new URLSearchParams(remainingParams).toString();
  const newSearch = newUrlParams ? `?${newUrlParams}` : "";
  const fullPath = pathname ? `${pathname}${newSearch}` : "./";
  window.history.replaceState({}, document.title, fullPath);

  return true;
}

export default urlDirectLogin;
