import React, { useEffect, useState } from "react";

import * as maiarId from "services/maiarId";
import { getLocalStorageItem, LocalStorageKeyEnum } from "../storage";
import urlDirectLogin from "./urlDirectLogin";
import { AccessTokenManagerContextProvider } from "../contexts/AccessTokenManagerContext";

import { TokenLoginType } from "types";

interface AccessTokenManagerPropsType {
  children: React.ReactNode;
  userAddress: string;
  loggedIn: boolean;
  data?: any;
  onTokenExpired?: () => void;
  tokenLogin: TokenLoginType | null;
  maiarIdApi: string;
  timeout?: number;
  shouldInvalidateToken?: boolean;
}

const defaultTimeout = 10_000;

export const AccessTokenManager = ({
  children,
  userAddress,
  loggedIn,
  tokenLogin,
  maiarIdApi,
  data,
  onTokenExpired,
  shouldInvalidateToken = false,
  timeout = defaultTimeout,
}: AccessTokenManagerPropsType) => {
  const [hasAccessToken, setHasAccessToken] = useState(false);
  const [isInitialized, setisInitialized] = useState(false);

  useEffect(() => {
    initLogin();
  }, [loggedIn, userAddress, tokenLogin?.loginToken, tokenLogin?.signature]);

  async function handleTokenExpired() {
    setHasAccessToken(false);
    onTokenExpired?.();
  }

  async function initLogin() {
    const shouldAuthenticateUser =
      loggedIn && tokenLogin?.loginToken != null && Boolean(userAddress);

    if (shouldAuthenticateUser) {
      try {
        await handleAuthenticateUser();
      } catch {
        setHasAccessToken(false);
      }
    } else {
      if (hasAccessToken) {
        setHasAccessToken(false);
      }
    }
    setisInitialized(true);
  }

  async function handleAuthenticateUser() {
    try {
      if (urlDirectLogin()) {
        return setHasAccessToken(true);
      }

      const loginToken = await getLocalStorageItem({
        address: userAddress,
        item: LocalStorageKeyEnum.maiarIdAccessToken,
      });
      if (loginToken != null) {
        try {
          //the user has an access token, this will use the refresh token to refresh the expiration time
          await maiarId.getAccessToken({
            address: userAddress,
            maiarIdApi,
            timeout,
            onTokenExpired: handleTokenExpired,
            shouldInvalidateToken,
          });
          setHasAccessToken(true);
        } catch (err) {
          console.log("error getting token, regenerating");
          await generateAuthTokenOnLogin();
        }
      } else {
        //the user logs in for the first time and this will generate the refreshToken
        await generateAuthTokenOnLogin();
      }
    } catch (err) {
      console.error("error getting token", err);
    }
  }

  async function generateAuthTokenOnLogin() {
    try {
      if (tokenLogin?.signature) {
        await maiarId.getRefreshToken({
          signature: tokenLogin?.signature,
          loginToken: tokenLogin?.loginToken,
          address: userAddress || "",
          maiarIdApi,
          data,
          timeout,
        });

        setHasAccessToken(true);
      } else {
        setHasAccessToken(false);
      }
    } catch (err) {
      console.error("Unable to get refresh token", err);
      setHasAccessToken(false);
      onTokenExpired?.();
    }
  }

  return isInitialized ? (
    <AccessTokenManagerContextProvider
      hasAccessToken={hasAccessToken}
      isInitialized={isInitialized}
    >
      {children}
    </AccessTokenManagerContextProvider>
  ) : null;
};

export default AccessTokenManager;
