import { useEffect, useRef, useState } from "react";
import { getAccessToken } from "../services/maiarId";
import { ErrorsEnum } from "../types";
import { useGetHasAccessToken } from "./useGetHasAccessToken";

export function useGetIsAuthenticated(
  address: string,
  maiarIdApi: string,
  isLoggedIn: boolean = true
) {
  const hasAccessToken = useGetHasAccessToken();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const timeoutRef = useRef<any>();
  const isMountedRef = useRef<any>(true);

  async function checkIfUserIsAuthenticated(retries = 0) {
    if (!isLoggedIn) {
      if (accessToken != null) {
        setAccessToken(null);
      }
      return;
    }
    try {
      const token = await getAccessToken({
        address,
        maiarIdApi,
      });
      if (isMountedRef.current && token) {
        setAccessToken(token?.accessToken);
      }
    } catch (err) {
      if (err === ErrorsEnum.accessTokenNotFound && retries < 6) {
        setTimeout(() => {
          checkIfUserIsAuthenticated(retries + 1);
        }, 200);
      } else {
        setIsTokenExpired(true);
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, [isLoggedIn, address, hasAccessToken]);

  return {
    isLoggedIn,
    isLoading,
    hasAccessToken: accessToken != null,
    isAuthenticated: isLoggedIn && accessToken != null,
    isTokenExpired,
    accessToken,
  };
}
